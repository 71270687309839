<template>
  <div class="aboutMeView">
    <pageHead title="评论" />
    <div v-infinite-scroll="loadMore" class="aboutMeView_content">
      <topicCommunicate
        :isShowReply="true"
        v-for="item in records"
        :key="item.id"
        :data="item"
        type="comment"
        class="likeView_content_item"
        @reply="handleReplyComment"
      />
      <no-more v-if="!params.hasNextPage" style="margin-top: 14px" />
    </div>
    <commentToolDialog
      v-model="editComment"
      :show-dialog.sync="showCommentToolDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      hideRelation
      hide-img
      @publish="handlePublishTopicComment"
    />
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import topicCommunicate from '@/components/topicItem/communicate'
import { listCommentMessage, replyCommentinfo } from '@/api/message'
import NoMore from '@/components/uitls/NoMore'
import commentToolDialog from '@/components/commentToolDialog'

export default {
  components: {
    pageHead,
    topicCommunicate,
    NoMore,
    commentToolDialog
  },
  data() {
    return {
      params: {
        type: 3,
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true
      },
      records: [],
      editComment: '',
      // 展示评论弹窗
      showCommentToolDialog: false,
      isAnonymousComment:false,
      commentParams: {}
    }
  },
  methods: {
    loadMore() {
      this.loadList()
    },
    loadList() {
      let listQueryParams = this.params
      if (listQueryParams.hasNextPage) {
        listCommentMessage(this.params).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages
          listQueryParams.pageNum = listQueryParams.pageNum + 1
          let records = this.records
          res.records.forEach((item) => {
            let count = records.filter(
              (innerItem) => innerItem.messageId === item.messageId
            )
            if (count.length === 0) {
              records.push(item)
            }
          })
          this.records = records
        })
      }
    },
    /**
     * 回复评论
     */
    handleReplyComment(data) {
      // if (this.interactionType !== 'comment') {
      //   return
      // }
      this.commentParams = {
        messageId: data.messageId
      }
      this.showCommentToolDialog = true
    },
    /**
     * 提交评论
     */
    async handlePublishTopicComment() {
      const { editComment: content, commentParams,isAnonymousComment:isAnonymous } = this
      if (!content.trim()) {
        this.$notify({ message: '评论内容不能为空', type: 'danger' })
        return
      }
      await replyCommentinfo({ content, isAnonymous,...commentParams })
      this.editComment = ''
      this.showCommentToolDialog = false
      this.$notify({ message: '回复成功', type: 'success' })
    }
  }
}
</script>

<style lang="scss" scoped>
.aboutMeView {
  height: 100%;
  display: flex;
  flex-direction: column;

  &_content {
    flex: 1;
    overflow-y: auto;

    &_item {
      margin-top: 10px;
    }
  }
}
</style>
